// export const siteurl = "https://conserve.speckledigital.com";
// export const environment_url = "https://conserve.speckledigital.com";
export const helpScout_url = "https://docsapi.helpscout.net/v1";
export const helpScout_api_key = "995998d9aafc8b054aef60b3f739e6f9a2aaa3a6";
export const helpScout_password = "gdApjtqkR0xYQyl";
export const worker_collectionId = "62734ca3c5cfff5d83fcd2df";
export const provider_collectionId = "625389244886273dd3a26c19";
export const client_collectionId = "62734c4665a63f340f70e364";

export const siteurl = process.env.REACT_APP_API_URL_DEV;
export const environment_url = process.env.REACT_APP_API_URL_PROD;
// export const google_client_secret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
// export const google_client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  
/*** SignIn ***/
export const SignIn_START = "SignIn_START";
export const SignIn_SUCCESS = "SignIn_SUCCESS";
export const SignIn_FAIL = "SignIn_FAIL";

export const SignInVerify_START = "SignInVerify_START";
export const SignInVerify_SUCCESS = "SignInVerify_SUCCESS";
export const SignInVerify_FAIL = "SignInVerify_FAIL";

export const email_START = "email_START";
export const email_SUCCESS = "email_SUCCESS";
export const email_FAIL = "email_FAIL";

export const ForgotPassword_START = "ForgotPassword_START";
export const ForgotPassword_SUCCESS = "ForgotPassword_SUCCESS";
export const ForgotPassword_FAIL = "ForgotPassword_FAIL";

export const ResendSignInOtp_START = "ResendSignInOtp_START";
export const ResendSignInOtp_SUCCESS = "ResendSignInOtp_SUCCESS";
export const ResendSignInOtp_FAIL = "ResendSignInOtp_FAIL";

export const ResetPassword_START = "ResetPassword_START";
export const ResetPassword_SUCCESS = "ResetPassword_SUCCESS";
export const ResetPassword_FAIL = "ResetPassword_FAIL";

/*** Admin ***/
// Admin- DocumentType
export const AddDocumentType_START = "AddDocumentType_START";
export const AddDocumentType_SUCCESS = "AddDocumentType_SUCCESS";
export const AddDocumentType_FAIL = "AddDocumentType_FAIL";

export const AdminGetAllDocumentType_START = "AdminGetAllDocumentType_START";
export const AdminGetAllDocumentType_SUCCESS =
  "AdminGetAllDocumentType_SUCCESS";
export const AdminGetAllDocumentType_FAIL = "AdminGetAllDocumentType_FAIL";

// Admin- EmailTemplates
export const CreateEmailTemplate_START = "CreateEmailTemplate_START";
export const CreateEmailTemplate_SUCCESS = "CreateEmailTemplate_SUCCESS";
export const CreateEmailTemplate_FAIL = "CreateEmailTemplate_FAIL";

export const GetEmailTemplateById_START = "GetEmailTemplateById_START";
export const GetEmailTemplateById_SUCCESS = "GetEmailTemplateById_SUCCESS";
export const GetEmailTemplateById_FAIL = "GetEmailTemplateById_FAIL";

export const GetAllEmailTemplatesByType_START =
  "GetAllEmailTemplatesByType_START";
export const GetAllEmailTemplatesByType_SUCCESS =
  "GetAllEmailTemplatesByType_SUCCESS";
export const GetAllEmailTemplatesByType_FAIL =
  "GetAllEmailTemplatesByType_FAIL";

export const UpdateEmailTemplate_START = "UpdateEmailTemplate_START";
export const UpdateEmailTemplate_SUCCESS = "UpdateEmailTemplate_SUCCESS";
export const UpdateEmailTemplate_FAIL = "UpdateEmailTemplate_FAIL";

export const DeleteEmailTemplate_START = "DeleteEmailTemplate_START";
export const DeleteEmailTemplate_SUCCESS = "DeleteEmailTemplate_SUCCESS";
export const DeleteEmailTemplate_FAIL = "DeleteEmailTemplate_FAIL";

export const DeleteEmailTempAttachmentById_START =
  "DeleteEmailTempAttachmentById_START";
export const DeleteEmailTempAttachmentById_SUCCESS =
  "DeleteEmailTempAttachmentById_SUCCESS";
export const DeleteEmailTempAttachmentById_FAIL =
  "DeleteEmailTempAttachmentById_FAIL";

// Admin- Service Types

export const CreateServiceType_START = "CreateServiceType_START";
export const CreateServiceType_SUCCESS = "CreateServiceType_SUCCESS";
export const CreateServiceType_FAIL = "CreateServiceType_FAIL";

export const GetAllServiceTypes_START = "GetAllServiceTypes_START";
export const GetAllServiceTypes_SUCCESS = "GetAllServiceTypes_SUCCESS";
export const GetAllServiceTypes_FAIL = "GetAllServiceTypes_FAIL";

// Admin- Provider Types
export const CreateProviderType_START = "CreateProviderType_START";
export const CreateProviderType_SUCCESS = "CreateProviderType_SUCCESS";
export const CreateProviderType_FAIL = "CreateProviderType_FAIL";

export const GetAllProviderTypes_START = "GetAllProviderTypes_START";
export const GetAllProviderTypes_SUCCESS = "GetAllProviderTypes_SUCCESS";
export const GetAllProviderTypes_FAIL = "GetAllProviderTypes_FAIL";

// admin- user

export const InviteUser_START = "InviteUser_START";
export const InviteUser_SUCCESS = "InviteUser_SUCCESS";
export const InviteUser_FAIL = "InviteUser_FAIL";

export const GetAllConserveTeamAndInvites_START =
  "GetAllConserveTeamAndInvites_START";
export const GetAllConserveTeamAndInvites_SUCCESS =
  "GetAllConserveTeamAndInvites_SUCCESS";
export const GetAllConserveTeamAndInvites_FAIL =
  "GetAllConserveTeamAndInvites_FAIL";

export const GetInvitationById_START = "GetInvitationById_START";
export const GetInvitationById_SUCCESS = "GetInvitationById_SUCCESS";
export const GetInvitationById_FAIL = "GetInvitationById_FAIL";

export const SubmitAdminUserInvitation_START =
  "SubmitAdminUserInvitation_START";
export const SubmitAdminUserInvitation_SUCCESS =
  "SubmitAdminUserInvitation_SUCCESS";
export const SubmitAdminUserInvitation_FAIL = "SubmitAdminUserInvitation_FAIL";

export const GetUserDetailsById_START = "GetUserDetailsById_START";
export const GetUserDetailsById_SUCCESS = "GetUserDetailsById_SUCCESS";
export const GetUserDetailsById_FAIL = "GetUserDetailsById_FAIL";

export const UpdateUser_START = "UpdateUser_START";
export const UpdateUser_SUCCESS = "UpdateUser_SUCCESS";
export const UpdateUser_FAIL = "UpdateUser_FAIL";

export const UserResetPasswordEmail_START = "UserResetPasswordEmail_START";
export const UserResetPasswordEmail_SUCCESS = "UserResetPasswordEmail_SUCCESS";
export const UserResetPasswordEmail_FAIL = "UserResetPasswordEmail_FAIL";

export const RemoveUser_START = "RemoveUser_START";
export const RemoveUser_SUCCESS = "RemoveUser_SUCCESS";
export const RemoveUser_FAIL = "RemoveUser_FAIL";

export const UserResetPassword_START = "UserResetPassword_START";
export const UserResetPassword_SUCCESS = "UserResetPassword_SUCCESS";
export const UserResetPassword_FAIL = "UserResetPassword_FAIL";

export const ReinviteAdminUser_START = "ReinviteAdminUser_START";
export const ReinviteAdminUser_SUCCESS = "ReinviteAdminUser_SUCCESS";
export const ReinviteAdminUser_FAIL = "ReinviteAdminUser_FAIL";

export const GetallEmailLogs_START = "GetallEmailLogs_START";
export const GetallEmailLogs_SUCCESS = "GetallEmailLogs_SUCCESS";
export const GetallEmailLogs_FAIL = "GetallEmailLogs_FAIL";

export const GetEmailLogById_START = "GetEmailLogById_START";
export const GetEmailLogById_SUCCESS = "GetEmailLogById_SUCCESS";
export const GetEmailLogById_FAIL = "GetEmailLogById_FAIL";

export const GetSpecificUserRole_START = "GetSpecificUserRole_START";
export const GetSpecificUserRole_SUCCESS = "GetSpecificUserRole_SUCCESS";
export const GetSpecificUserRole_FAIL = "GetSpecificUserRole_FAIL";

// Public

export const GetAllContactTypes_START = "GetAllContactTypes_START";
export const GetAllContactTypes_SUCCESS = "GetAllContactTypes_SUCCESS";
export const GetAllContactTypes_FAIL = "GetAllContactTypes_FAIL";

export const GetAllFunctions_START = "GetAllFunctions_START";
export const GetAllFunctions_SUCCESS = "GetAllFunctions_SUCCESS";
export const GetAllFunctions_FAIL = "GetAllFunctions_FAIL";

export const GetAllRoleByFunctionId_START = "GetAllRoleByFunctionId_START";
export const GetAllRoleByFunctionId_SUCCESS = "GetAllRoleByFunctionId_SUCCESS";
export const GetAllRoleByFunctionId_FAIL = "GetAllRoleByFunctionId_FAIL";

export const GetAllCountries_START = "GetAllCountries_START";
export const GetAllCountries_SUCCESS = "GetAllCountries_SUCCESS";
export const GetAllCountries_FAIL = "GetAllCountries_FAIL";

export const GetAllStatesbyCountryId_START = "GetAllStatesbyCountryId_START";
export const GetAllStatesbyCountryId_SUCCESS =
  "GetAllStatesbyCountryId_SUCCESS";
export const GetAllStatesbyCountryId_FAIL = "GetAllStatesbyCountryId_FAIL";

export const GetHighRiskActivityList_START = "GetHighRiskActivityList_START";
export const GetHighRiskActivityList_SUCCESS =
  "GetHighRiskActivityList_SUCCESS";
export const GetHighRiskActivityList_FAIL = "GetHighRiskActivityList_FAIL";

export const GetAllOrgIndustryTypeList_START =
  "GetAllOrgIndustryTypeList_START";
export const GetAllOrgIndustryTypeList_SUCCESS =
  "GetAllOrgIndustryTypeList_SUCCESS";
export const GetAllOrgIndustryTypeList_FAIL = "GetAllOrgIndustryTypeList_FAIL";

export const GetIndividualByEmail_START = "GetIndividualByEmail_START";
export const GetIndividualByEmail_SUCCESS = "GetIndividualByEmail_SUCCESS";
export const GetIndividualByEmail_FAIL = "GetIndividualByEmail_FAIL";

export const SET_FUNCTION_ASSIGNMENT_ID = "SET_FUNCTION_ASSIGNMENT_ID";
export const SET_ALL_FUNCTION = "SET_ALL_FUNCTION";
export const SET_ALL_ROLE = "SET_ALL_ROLE";

export const GetOrgUserInvitationById_START = "GetOrgUserInvitationById_START";
export const GetOrgUserInvitationById_SUCCESS =
  "GetOrgUserInvitationById_SUCCESS";
export const GetOrgUserInvitationById_FAIL = "GetOrgUserInvitationById_FAIL";

export const SubmitUserInvitation_START = "SubmitUserInvitation_START";
export const SubmitUserInvitation_SUCCESS = "SubmitUserInvitation_SUCCESS";
export const SubmitUserInvitation_FAIL = "SubmitUserInvitation_FAIL";

// Admin - Client

export const AddClient_START = "AddClient_START";
export const AddClient_SUCCESS = "AddClient_SUCCESS";
export const AddClient_FAIL = "AddClient_FAIL";

export const GetAllParentClientList_START = "GetAllParentClientList_START";
export const GetAllParentClientList_SUCCESS = "GetAllParentClientList_SUCCESS";
export const GetAllParentClientList_FAIL = "GetAllParentClientList_FAIL";

export const AdminGetAllClients_START = "AdminGetAllClients_START";
export const AdminGetAllClients_SUCCESS = "AdminGetAllClients_SUCCESS";
export const AdminGetAllClients_FAIL = "AdminGetAllClients_FAIL";

export const GetClientOverviewProfileById_START =
  "GetClientOverviewProfileById_START";
export const GetClientOverviewProfileById_SUCCESS =
  "GetClientOverviewProfileById_SUCCESS";
export const GetClientOverviewProfileById_FAIL =
  "GetClientOverviewProfileById_FAIL";

export const CreateComplianceDoc_START = "CreateComplianceDoc_START";
export const CreateComplianceDoc_SUCCESS = "CreateComplianceDoc_SUCCESS";
export const CreateComplianceDoc_FAIL = "CreateComplianceDoc_FAIL";

export const GetAllDocumentTypeList_START = "GetAllDocumentTypeList_START";
export const GetAllDocumentTypeList_SUCCESS = "GetAllDocumentTypeList_SUCCESS";
export const GetAllDocumentTypeList_FAIL = "GetAllDocumentTypeList_FAIL";

export const GetAllComplianceDocsOfClient_START =
  "GetAllComplianceDocsOfClient_START";
export const GetAllComplianceDocsOfClient_SUCCESS =
  "GetAllComplianceDocsOfClient_SUCCESS";
export const GetAllComplianceDocsOfClient_FAIL =
  "GetAllComplianceDocsOfClient_FAIL";

export const CreateOrgNote_START = "CreateOrgNote_START";
export const CreateOrgNote_SUCCESS = "CreateOrgNote_SUCCESS";
export const CreateOrgNote_FAIL = "CreateOrgNote_FAIL";

export const GetSpecificOrgNotes_START = "GetSpecificOrgNotes_START";
export const GetSpecificOrgNotes_SUCCESS = "GetSpecificOrgNotes_SUCCESS";
export const GetSpecificOrgNotes_FAIL = "GetSpecificOrgNotes_FAIL";

export const GetAllProvidersPrimaryList_START =
  "GetAllProvidersPrimaryList_START";
export const GetAllProvidersPrimaryList_SUCCESS =
  "GetAllProvidersPrimaryList_SUCCESS";
export const GetAllProvidersPrimaryList_FAIL =
  "GetAllProvidersPrimaryList_FAIL";

export const InviteSpecificContractor_START = "InviteSpecificContractor_START";
export const InviteSpecificContractor_SUCCESS =
  "InviteSpecificContractor_SUCCESS";
export const InviteSpecificContractor_FAIL = "InviteSpecificContractor_FAIL";

export const GetAllIndividualListForContact_START =
  "GetAllIndividualListForContact_START";
export const GetAllIndividualListForContact_SUCCESS =
  "GetAllIndividualListForContact_SUCCESS";
export const GetAllIndividualListForContact_FAIL =
  "GetAllIndividualListForContact_FAIL";

export const AddClientContact_START = "AddClientContact_START";
export const AddClientContact_SUCCESS = "AddClientContact_SUCCESS";
export const AddClientContact_FAIL = "AddClientContact_FAIL";

export const GetAllInvitedProvidersOfClient_START =
  "GetAllInvitedProvidersOfClient_START";
export const GetAllInvitedProvidersOfClient_SUCCESS =
  "GetAllInvitedProvidersOfClient_SUCCESS";
export const GetAllInvitedProvidersOfClient_FAIL =
  "GetAllInvitedProvidersOfClient_FAIL";

export const ReinviteContractor_START = "ReinviteContractor_START";
export const ReinviteContractor_SUCCESS = "ReinviteContractor_SUCCESS";
export const ReinviteContractor_FAIL = "ReinviteContractor_FAIL";

export const RemoveAdminInviteUser_START = "RemoveAdminInviteUser_START";
export const RemoveAdminInviteUser_SUCCESS = "RemoveAdminInviteUser_SUCCESS";
export const RemoveAdminInviteUser_FAIL = "RemoveAdminInviteUser_FAIL";

export const GetAllClientContacts_START = "GetAllClientContacts_START";
export const GetAllClientContacts_SUCCESS = "GetAllClientContacts_SUCCESS";
export const GetAllClientContacts_FAIL = "GetAllClientContacts_FAIL";

export const GetClientContactById_START = "GetClientContactById_START";
export const GetClientContactById_SUCCESS = "GetClientContactById_SUCCESS";
export const GetClientContactById_FAIL = "GetClientContactById_FAIL";

export const UpdateClientContact_START = "UpdateClientContact_START";
export const UpdateClientContact_SUCCESS = "UpdateClientContact_SUCCESS";
export const UpdateClientContact_FAIL = "UpdateClientContact_FAIL";

export const GetAllIndividualListForInvite_START =
  "GetAllIndividualListForInvite_START";
export const GetAllIndividualListForInvite_SUCCESS =
  "GetAllIndividualListForInvite_SUCCESS";
export const GetAllIndividualListForInvite_FAIL =
  "GetAllIndividualListForInvite_FAIL";

export const DeleteClientContact_START = "DeleteClientContact_START";
export const DeleteClientContact_SUCCESS = "DeleteClientContact_SUCCESS";
export const DeleteClientContact_FAIL = "DeleteClientContact_FAIL";

export const InviteOrgUser_START = "InviteOrgUser_START";
export const InviteOrgUser_SUCCESS = "InviteOrgUser_SUCCESS";
export const InviteOrgUser_FAIL = "InviteOrgUser_FAIL";

export const GetAllSpecificOrgUsers_START = "GetAllSpecificOrgUsers_START";
export const GetAllSpecificOrgUsers_SUCCESS = "GetAllSpecificOrgUsers_SUCCESS";
export const GetAllSpecificOrgUsers_FAIL = "GetAllSpecificOrgUsers_FAIL";

export const GetAllSpecificOrgInvites_START = "GetAllSpecificOrgInvites_START";
export const GetAllSpecificOrgInvites_SUCCESS =
  "GetAllSpecificOrgInvites_SUCCESS";
export const GetAllSpecificOrgInvites_FAIL = "GetAllSpecificOrgInvites_FAIL";

export const ReinviteOrgUser_START = "ReinviteOrgUser_START";
export const ReinviteOrgUser_SUCCESS = "ReinviteOrgUser_SUCCESS";
export const ReinviteOrgUser_FAIL = "ReinviteOrgUser_FAIL";

export const GetSpecificClientDetailsById_START =
  "GetSpecificClientDetailsById_START";
export const GetSpecificClientDetailsById_SUCCESS =
  "GetSpecificClientDetailsById_SUCCESS";
export const GetSpecificClientDetailsById_FAIL =
  "GetSpecificClientDetailsById_FAIL";

export const UpdateClientDetails_START = "UpdateClientDetails_START";
export const UpdateClientDetails_SUCCESS = "UpdateClientDetails_SUCCESS";
export const UpdateClientDetails_FAIL = "UpdateClientDetails_FAIL";

export const UpdateOrgStatus_START = "UpdateOrgStatus_START";
export const UpdateOrgStatus_SUCCESS = "UpdateOrgStatus_SUCCESS";
export const UpdateOrgStatus_FAIL = "UpdateOrgStatus_FAIL";

export const GetAllContractorPrimaryList_START =
  "GetAllContractorPrimaryList_START";
export const GetAllContractorPrimaryList_SUCCESS =
  "GetAllContractorPrimaryList_SUCCESS";
export const GetAllContractorPrimaryList_FAIL =
  "GetAllContractorPrimaryList_FAIL";

export const GetAllInvitedContractorsOfClient_START =
  "GetAllInvitedContractorsOfClient_START";
export const GetAllInvitedContractorsOfClient_SUCCESS =
  "GetAllInvitedContractorsOfClient_SUCCESS";
export const GetAllInvitedContractorsOfClient_FAIL =
  "GetAllInvitedContractorsOfClient_FAIL";

export const GetClientOtherDetailsById_START =
  "GetClientOtherDetailsById_START";
export const GetClientOtherDetailsById_SUCCESS =
  "GetClientOtherDetailsById_SUCCESS";
export const GetClientOtherDetailsById_FAIL = "GetClientOtherDetailsById_FAIL";

export const UpdateClientAccreditations_START =
  "UpdateClientAccreditations_START";
export const UpdateClientAccreditations_SUCCESS =
  "UpdateClientAccreditations_SUCCESS";
export const UpdateClientAccreditations_FAIL =
  "UpdateClientAccreditations_FAIL";

export const UpdateComplianceChecklistReviewDate_START =
  "UpdateComplianceChecklistReviewDate_START";
export const UpdateComplianceChecklistReviewDate_SUCCESS =
  "UpdateComplianceChecklistReviewDate_SUCCESS";
export const UpdateComplianceChecklistReviewDate_FAIL =
  "UpdateComplianceChecklistReviewDate_FAIL";

export const GetOrgUserPermById_START = "GetOrgUserPermById_START";
export const GetOrgUserPermById_SUCCESS = "GetOrgUserPermById_SUCCESS";
export const GetOrgUserPermById_FAIL = "GetOrgUserPermById_FAIL";

export const UpdateOrgUserPerm_START = "UpdateOrgUserPerm_START";
export const UpdateOrgUserPerm_SUCCESS = "UpdateOrgUserPerm_SUCCESS";
export const UpdateOrgUserPerm_FAIL = "UpdateOrgUserPerm_FAIL";

export const CreateCompanyInductionForClient_START =
  "CreateCompanyInductionForClient_START";
export const CreateCompanyInductionForClient_SUCCESS =
  "CreateCompanyInductionForClient_SUCCESS";
export const CreateCompanyInductionForClient_FAIL =
  "CreateCompanyInductionForClient_FAIL";

export const CreateCompanyInductionModule_START =
  "CreateCompanyInductionModule_START";
export const CreateCompanyInductionModule_SUCCESS =
  "CreateCompanyInductionModule_SUCCESS";
export const CreateCompanyInductionModule_FAIL =
  "CreateCompanyInductionModule_FAIL";

export const SET_COMPANY_iNDUCTION_MODULE = "SET_COMPANY_iNDUCTION_MODULE";

export const GetAllEmailTemplateForClientsEmail_START =
  "GetAllEmailTemplateForClientsEmail_START";
export const GetAllEmailTemplateForClientsEmail_SUCCESS =
  "GetAllEmailTemplateForClientsEmail_SUCCESS";
export const GetAllEmailTemplateForClientsEmail_FAIL =
  "GetAllEmailTemplateForClientsEmail_FAIL";

export const GetClientIndividualForEmail_START =
  "GetClientIndividualForEmail_START";
export const GetClientIndividualForEmail_SUCCESS =
  "GetClientIndividualForEmail_SUCCESS";
export const GetClientIndividualForEmail_FAIL =
  "GetClientIndividualForEmail_FAIL";

export const EmailToClientIndividual_START = "EmailToClientIndividual_START";
export const EmailToClientIndividual_SUCCESS =
  "EmailToClientIndividual_SUCCESS";
export const EmailToClientIndividual_FAIL = "EmailToClientIndividual_FAIL";

export const GetAdminProfileById_START = "GetAdminProfileById_START";
export const GetAdminProfileById_SUCCESS = "GetAdminProfileById_SUCCESS";
export const GetAdminProfileById_FAIL = "GetAdminProfileById_FAIL";

export const CreateSite_START = "CreateSite_START";
export const CreateSite_SUCCESS = "CreateSite_SUCCESS";
export const CreateSite_FAIL = "CreateSite_FAIL";

// export const GetAllInductionsOfSpecificSite_START = "GetAllInductionsOfSpecificSite_START";
// export const GetAllInductionsOfSpecificSite_SUCCESS = "GetAllInductionsOfSpecificSite_SUCCESS";
// export const GetAllInductionsOfSpecificSite_FAIL = "GetAllInductionsOfSpecificSite_FAIL";

export const GetClientSiteById_START = "GetClientSiteById_START";
export const GetClientSiteById_SUCCESS = "GetClientSiteById_SUCCESS";
export const GetClientSiteById_FAIL = "GetClientSiteById_FAIL";

export const RemoveClientSiteById_START = "RemoveClientSiteById_START";
export const RemoveClientSiteById_SUCCESS = "RemoveClientSiteById_SUCCESS";
export const RemoveClientSiteById_FAIL = "RemoveClientSiteById_FAIL";

export const GetAllSitesOfClient_START = "GetAllSitesOfClient_START";
export const GetAllSitesOfClient_SUCCESS = "GetAllSitesOfClient_SUCCESS";
export const GetAllSitesOfClient_FAIL = "GetAllSitesOfClient_FAIL";

export const UpdateClientSite_START = "UpdateClientSite_START";
export const UpdateClientSite_SUCCESS = "UpdateClientSite_SUCCESS";
export const UpdateClientSite_FAIL = "UpdateClientSite_FAIL";

export const DeleteSiteEvacuationDiagramById_START =
  "DeleteSiteEvacuationDiagramById_START";
export const DeleteSiteEvacuationDiagramById_SUCCESS =
  "DeleteSiteEvacuationDiagramById_SUCCESS";
export const DeleteSiteEvacuationDiagramById_FAIL =
  "DeleteSiteEvacuationDiagramById_FAIL";

export const GetAlreadyExistingOrgCheck_START =
  "GetAlreadyExistingOrgCheck_START";
export const GetAlreadyExistingOrgCheck_SUCCESS =
  "GetAlreadyExistingOrgCheck_SUCCESS";
export const GetAlreadyExistingOrgCheck_FAIL =
  "GetAlreadyExistingOrgCheck_FAIL";

export const GetCompDocsListOfWorkerType_START =
  "GetCompDocsListOfWorkerType_START";
export const GetCompDocsListOfWorkerType_SUCCESS =
  "GetCompDocsListOfWorkerType_SUCCESS";
export const GetCompDocsListOfWorkerType_FAIL =
  "GetCompDocsListOfWorkerType_FAIL";

export const UpdateSiteActiveStatus_START = "UpdateSiteActiveStatus_START";
export const UpdateSiteActiveStatus_SUCCESS = "UpdateSiteActiveStatus_SUCCESS";
export const UpdateSiteActiveStatus_FAIL = "UpdateSiteActiveStatus_FAIL";

export const RemoveOrgUserInvitation_START = "RemoveOrgUserInvitation_START";
export const RemoveOrgUserInvitation_SUCCESS =
  "RemoveOrgUserInvitation_SUCCESS";
export const RemoveOrgUserInvitation_FAIL = "RemoveOrgUserInvitation_FAIL";

export const RemoveClientUser_START = "RemoveClientUser_START";
export const RemoveClientUser_SUCCESS = "RemoveClientUser_SUCCESS";
export const RemoveClientUser_FAIL = "RemoveClientUser_FAIL";
